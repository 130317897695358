@import 'variable';
@import 'mixin';

/* ======================================================================
 p_news_box
====================================================================== */
//p_news_box_bg
.p_news_box_bg{
  background: linear-gradient(90deg, $white 0%, $white 50%, $color_4 50%, $color_4 100%);
  @include sp{
    background: $white;
  }
}
// p_news_box_wrap
.p_news_box_wrap{
  @include pc_tab{
    display: flex;
    width: 100%;
    max-width: $content_width_3;
    margin: 0 auto;
  }
}
// p_news_box
.p_news_box{
  padding: 40px 20px 60px;
  .c_btn_3{
    margin-top: 30px;
  }

  @include pc_tab{
    padding: 70px;
  }
  @include tab_content{
    padding: 40px;
  }
  @include sp{
    &:last-child{
      background: $color_4;
    }
    .c_ttl_3{
      margin-bottom: 0;
    }
  }
}
// p_news_card
.p_news_card {
  display: block;
  padding: 20px 0;
  border-bottom: 1px solid $color_5;
  transition: $all;
  .date, .time, .cat{
    font-size: 1.2rem;
  }
  .time {
    color: $color_6;
    margin-left: 8px;
  }
  .ttl{
    margin-top: 10px;
    color: $color_3;
    font-size: 1.4rem;
    font-weight: 700;
  }
  .cat{
    display: inline-block;
    padding: 4px 10px;
    border: 1px solid $color_6;
    color: $color_6;
    font-weight: 700;
    margin-left: 10px;
  }
  &:hover {
      opacity: 0.5;
  }
}


