//======================================================================
// variable
//======================================================================
// imgディレクトリまでのパス
$img_dir: '/img';

// コンテンツ幅
$content_width: 1100px;
$content_width_2: 1156px;
$content_width_3: 1366px;
$content_width_4: 1200px;
$content_width_5: 800px;
$content_width_6: 1234px;
$content_width_7: 310px;
$content_width_8: 920px;

// SP時の左右の余白（片方）
$sp_side_padding: 20px;

//=================================
// breakpoint
//=================================
// 下記2つの変数の間がタブレット表示
// タブレット表示なしの場合は2つの変数の値を「1px差」で設定すること

// $pc_bp以上がPC表示
$pc_bp: 1100px;

// $sp_bp以下がSP表示
$sp_bp: 767px;

//=================================
// font
//=================================
$font_1: '游ゴシック', YuGothic, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', sans-serif;
$font_2: 'Shippori Mincho', 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3', '游明朝', Yu mincho, YuMincho, serif;


//=================================
// color
//=================================
$black: #000;

$white: #fff;

$color_1: #222;

$color_2: #00153C;

$color_3: #043288;
$color_3_B: #033288;

$color_4: #F5F5F5;
$color_4_B: #F8F9FA;

$color_5: #EAECEF;//ボタン・下線・罫線・枠線

$color_6: #EACB52;

$color_7: #E5EAF3;

$color_8: #e0e0e0;

$color_10: #f7f7f7;

$color_11: #D8D2B9;

$color_12: #EAE8E5;

$color_13: #EAECEF;//ボタン・下線・罫線・枠線

$color_14: #D8D8D8;

$color_15: #c7c7c7;

$color_16: #C9B55E;

$color_17: #701930;

$color_18: #282A2F;

$color_19: #747474;

$color_20: #143C89;

$color_21: #0E244F;

$color_22: #E2E2E2;

$color_23: #0092D3;

$color_24: #D3C58B;

$color_25: #26385D;

$color_26: #929BAD;

$color_27: #D3C58B66;

$color_28: #FF1717;

$color_29: #999999;

$color_30: #65B1F2;

$color_31: #01153C;

$color_32: #E5E5E5;

$color_33: #B1B1B1;

$color_34: #A68685;

$color_35: #46B1FF;

$color_36: #5C956B;

$color_37: #355064;

$color_38: #422642;

$color_39: #EDB459;

$color_40: #CB3839;

$color_41: #0273AD;

$color_42: #197070;

$color_43: #525E8E;

$color_44: #e9e2c5;

$color_45: #FF003B;

$color_46: #C2AF63;







$color_sub_1: #8D1C31;

$color_sub_2: #FEE700;

$color_tp: transparent;
//=================================
// CSS Transition Easing
//=================================
// Default
$linear: cubic-bezier(0.250, 0.250, 0.750, 0.750);
$ease: cubic-bezier(0.250, 0.100, 0.250, 1.000);
$ease-in: cubic-bezier(0.420, 0.000, 1.000, 1.000);
$ease-out: cubic-bezier(0.000, 0.000, 0.580, 1.000);
$ease-in-out: cubic-bezier(0.420, 0.000, 0.580, 1.000);
// In
$easeInQuad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$easeInCubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$easeInQuart: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$easeInQuint: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$easeInSine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeInExpo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$easeInCirc: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeInBack: cubic-bezier(0.600, -0.280, 0.735, 0.045);
// Out
$easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeOutCubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeOutQuart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$easeOutQuint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$easeOutSine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeOutCirc: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275);
// In Out
$easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$easeInOutQuart: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$easeInOutQuint: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$easeInOutSine: cubic-bezier(0.445, 0.050, 0.550, 0.950);
$easeInOutExpo: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.150, 0.860);
$easeInOutBack: cubic-bezier(0.680, -0.550, 0.265, 1.550);
// In Out
$all: .3s all;
$all_2: .8s all;



